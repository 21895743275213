.btn-grad {
  background-image: linear-gradient(
    to right,
    #200122 0%,
    #6f0000 51%,
    #200122 100%
  );

  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  color: white;
  box-shadow: none;
  /* display: block; */
}
.hover-btn-grad:hover {
  background-image: linear-gradient(
    to right,
    #200122 0%,
    #6f0000 51%,
    #200122 100%
  );

  text-align: center;
  text-transform: uppercase;
  color: white;
  box-shadow: none;
  /* display: block; */
}
.focus-btn-grad:focus {
  background-image: linear-gradient(
    to right,
    #200122 0%,
    #6f0000 51%,
    #200122 100%
  );

  text-align: center;
  text-transform: uppercase;
  color: white;
  box-shadow: none;
  /* display: block; */
}

.short-btn {
  display: flex;
  justify-content: center;
  width: full;
  align-items: center;
}

.btn-grad-hover:hover {
  background-image: linear-gradient(
    to right,
    #200122 0%,
    #6f0000 51%,
    #200122 100%
  );

  text-align: center;
  text-transform: uppercase;
  transition: 0.5s;
  color: white;
  box-shadow: none;
  display: block;
}

.hover\:underlineAnimated::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  transform: translateX(full);
  width: 0;
  height: 2px;
  background-color: #8e0e00;
  transition: width 0.3s;
}

.hover\:underlineAnimated:hover::after,
.hover\:underlineAnimated:focus::after {
  width: 100%;
}

.custom-border {
  border-width: 0px;
  border-style: solid;
  border-radius: 0.75rem;
}

.custom-border-bottom {
  border-bottom-width: 2px;
  border-style: solid;
  border-color: #4d4d4d;
}

.custom-border-top {
  border-top-width: 2px;
  border-style: solid;
  border-color: #4d4d4d;
}

.traits-color {
  background-color: #e5e7eb;
}

.quantity-input {
  outline: none;
  text-align: center;
  border-radius: 0.75rem;
  border-top-width: 2px;
  border-style: solid;
  border-color: #e5e7eb;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.price-style {
  outline: none;
  border-style: solid;
  border-top-left-radius: 0.75rem;
  border-bottom-left-radius: 0.75rem;
  border-color: #e5e7eb;
}

.bg-comming-custom {
  background-image: url("/comingsoon_bg.jpg");
  background-size: cover;
  background-position: center;
}

.border-shadow {
  box-shadow: 0px 0px 40px 3px rgba(193, 82, 96, 1);
}

.gradient-text2 {
  background: linear-gradient(to right, red, #8e0e00, #6f0000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.create-button {
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 40px;
  color: #fff;
  font-size: 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1000;
  transition: transform 0.3s ease, opacity 0.3s ease;
  border-radius: 100px;
}

.create-button.hide {
  transform: translateX(-50%) translateY(100%);
  opacity: 0;
}
.loader {
  display: flex;
  align-items: center;
}

.bar {
  display: inline-block;
  width: 3px;
  height: 10px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  animation: scale-up4 1s linear infinite;
}

.bar:nth-child(2) {
  height: 20px;
  margin: 0 5px;
  animation-delay: 0.25s;
}

.bar:nth-child(3) {
  animation-delay: 0.5s;
}

@keyframes scale-up4 {
  20% {
    background-color: #ffff;
    transform: scaleY(1.5);
  }

  40% {
    transform: scaleY(1);
  }
}

/* // lazy card loading  */

.card {
  position: relative;
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
  background-color: #1e1e1e;
  border-radius: 6px;
  height: 360px;
  overflow: hidden;
  width: 260px;
  margin: 40px auto;
}
.card .shimmerBG {
  -webkit-animation-duration: 2.2s;
  animation-duration: 2.2s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: shimmer;
  animation-name: shimmer;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  background: #2b2b2b;
  background: linear-gradient(to right, #5a5959 8%, #484848 18%, #5d5c5c 33%);
  background-size: 1200px 100%;
}
@-webkit-keyframes shimmer {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}
@keyframes shimmer {
  0% {
    background-position: -1200px 0;
  }
  100% {
    background-position: 1200px 0;
  }
}
.card .media {
  height: 200px;
}
.card .p-32 {
  padding: 32px;
}
.card .title-line {
  height: 24px;
  width: 100%;
  margin-bottom: 12px;
  border-radius: 20px;
}
.card .content-line {
  height: 8px;
  width: 100%;
  margin-bottom: 16px;
  border-radius: 8px;
}
.card .end {
  width: 40%;
}

.m-t-24 {
  margin-top: 24px;
}

/* HTML: <div class="loader"></div> */
.spin-loader {
  width: 40px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #b70101 94%, #0000) top/8px 8px
      no-repeat,
    conic-gradient(#0000 30%, #b70101);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 6px), #000 0);
  animation: l13 1s infinite linear;
}

.mini-loader {
  width: 20px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: radial-gradient(farthest-side, #b70101 94%, #0000) top/8px 8px
      no-repeat,
    conic-gradient(#0000 30%, #b70101);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 10px), #000 0);
  animation: l13 1s infinite linear;
}

@keyframes l13 {
  100% {
    transform: rotate(1turn);
  }
}

/* // landing page  */

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

@media (min-width: 1024px) {
  .glass {
    background: rgba(104, 104, 104, 0.2);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.67);
    backdrop-filter: blur(0.1px);
    -webkit-backdrop-filter: blur(3px);
  }
}

.image {
  max-width: calc(230vw + 1670px);
  z-index: -9999;
}

.home-campaign-glowing-icon-glow {
  --mktg-accent-primary: #dd7df7;
}

.home-campaign-lines-hero {
  left: calc(50% - 12px);
  width: 300px;
  height: 428px;
}
@media (min-width: 768px) {
  .home-campaign-lines-hero {
    left: calc(50% - 14px);
    width: 437px;
    height: 643px;
  }
}

.copilot {
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 0 8px 32px 0 rgba(135, 31, 31, 0.318);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(3px);
}
.red-shadow {
  box-shadow: 0 8px 32px 0 rgba(135, 31, 31, 0.318);
  backdrop-filter: blur(1px);
  -webkit-backdrop-filter: blur(3px);
}

.home-campaign-signup-button {
  background: linear-gradient(
      180deg,
      rgba(183, 52, 179, 0.15) 0%,
      rgba(164, 46, 156, 0) 100%
    ),
    #6e40c9 !important;
}
.home-campaign-glowing-icon-glow-1 {
  background-color: #a11011;
  opacity: 0.4;
  filter: blur(20px);
}
.home-campaign-glowing-icon-glow-2 {
  background-color: #ffa28b;
  filter: blur(20px);
}
.home-campaign-glowing-icon-glow-3 {
  background-color: #939aff;
  filter: blur(20px);
}

.home-campaign-git-line {
  background: linear-gradient(#db2222, #a11011, var(--color-canvas-default));
  transition-delay: 500ms;
  width: 3px;
}

@media (max-width: 400px) {
  .line {
    height: 1080px;
  }
}
.pl-ent {
  color: #a11011;
}
.pl-e,
.pl-en {
  color: #d2a8ff;
}
.pl-s {
  color: #a5d6ff;
}
.pl-s1 {
  color: #fff;
}
.pl-k {
  color: #ff7b72;
}

.pl-c1 {
  color: #79c0ff;
}

.color-fg-subtle {
  color: #6e7681;
}
.color-fg-accent {
  color: #2f81f7;
}
.color-fg-done {
  color: #a371f7;
}

.increase {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(88, 133, 91, 0.9) 25.52%,
    #731414 53.12%,
    rgba(99, 199, 107, 0.544444) 74.48%,
    rgba(0, 0, 0, 0) 100%
  );
}

.increase1 {
  background: linear-gradient(#0d1117, #ea6045, #ea6045, #0d1117);
}
.increase2 {
  background: linear-gradient(#0d1117, #797ef9, #797ef9, #0d1117);
}

.back {
  background: #731414;
  filter: blur(180px);
}

.box-shadow-card-mktg {
  box-shadow: 0 3px 2px rgba(0, 0, 0, 0.04), 0 7px 5px rgba(0, 0, 0, 0.02),
    0 13px 10px rgba(0, 0, 0, 0.02), 0 22px 17px rgba(0, 0, 0, 0.02) !important;
}

.code-editor-line-mktg {
  border-left: 2px solid #0d1117;
  display: inline-block;
}

.code-editor-line-suggested-mktg {
  border-color: #1f6feb;
}

.box-shadow-mktg-xl {
  box-shadow: 0 2.74416px 2.74416px rgba(0, 0, 0, 0.0274351),
    0 5.48831px 5.48831px rgba(0, 0, 0, 0.0400741),
    0 13.7208px 10.9766px rgba(0, 0, 0, 0.0499982),
    0 20.5812px 20.5812px rgba(0, 0, 0, 0.0596004),
    0 41.1623px 41.1623px rgba(0, 0, 0, 0.0709366),
    0 96.0454px 89.1851px rgba(0, 0, 0, 0.09);
}

.responsive {
  width: 100% !important;
  height: auto !important;
}

.hero-drone {
  animation: animate 20s linear infinite;
}

@keyframes animate {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}
